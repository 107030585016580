import React, { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';
import { jwtDecode } from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { getConfiguration } from '~/utils/configurations';
import { initializeGTM } from '~/utils/gtm';

import { auth } from '../services/api/auth';
import { catchError, Errors, sleep, UserJwtPayload } from '../utils';
import { storeUser as storeUserAction } from '../store/user';
import { UserEntitlements, UserType } from '../types/users';
import { RootState } from '../store';

export const KEYS = {
  USER: 'BR_USER',
  PREVIOUS_ROUTE: 'PREVIOUS_ROUTE',
  INTENDED_ROUTE: 'INTENDED_ROUTE',
  INTENDED_SEARCH: 'INTENDED_SEARCH',
  LOGOUT_COOKIE: 'LOGOUT_COOKIE',
  LOGOUT_COOKIE_DOMAIN: getConfiguration('REACT_APP_DOMAIN'),
};

export const ROLES_NAMESPACE = 'https://anzarenewables.com/roles';

type Location = ReturnType<typeof useLocation>;

export const getUserFromLocalStorage = (): UserType | null => {
  const userFromLocalStorage = localStorage.getItem(KEYS.USER);

  return userFromLocalStorage ? JSON.parse(userFromLocalStorage) : null;
};

interface ErrorType {
  error: string;
  response: {
    data: {
      message: string;
      errorData?: {
        email_verified: string;
      };
    };
  };
}

const setUserToLocalStorage = (user: UserType): void => {
  localStorage.setItem(KEYS.USER, JSON.stringify(user));
};

const getLogoutCookie = () => {
  let [name, value] = ['', ''];
  const cookie = document.cookie
    .split('; ')
    .find((row) => row.startsWith(KEYS.LOGOUT_COOKIE));

  if (cookie) {
    [name, value] = cookie.split('=');
  }

  return [name, value];
};

const deleteLogoutCookie = () => {
  document.cookie = `${KEYS.LOGOUT_COOKIE}=${window.location.hostname}; domain=${KEYS.LOGOUT_COOKIE_DOMAIN}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'`;
};

const useAuth = () => {
  const [isAuthorized, setIsAuthorized] = React.useState(true);
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    user: _user,
  } = useAuth0();

  const roles = useMemo(() => _user?.[ROLES_NAMESPACE] ?? [], [_user]);
  const user = useSelector<RootState, UserType>((state) => state.user);
  const [entitlements, setEntitlements] = useState<UserEntitlements>();
  const dispatch = useDispatch();

  const signOutUser = (newLocation?: Location) => {
    if (newLocation?.pathname) {
      localStorage.setItem(KEYS.PREVIOUS_ROUTE, newLocation.pathname);
    } else {
      localStorage.removeItem(KEYS.PREVIOUS_ROUTE);
    }

    try {
      logout({
        returnTo: `${getConfiguration(
          'REACT_APP_ANZA_URL'
        )}/auth?action=logout`,
      });
    } catch (error: unknown) {
      catchError({ error });
    }
  };

  const getTokenAndSetUser = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: getConfiguration('REACT_APP_AUTH0_AUDIENCE'),
        scope: getConfiguration('REACT_APP_AUTH0_SCOPES'),
        ignoreCache: true,
      });

      const decoded = jwtDecode<UserJwtPayload>(accessToken);

      const response = await auth(accessToken);

      const userData = response.data;

      if (!userData.email_verified) {
        throw Error(Errors.EMAIL_NOT_VERIFIED);
      }

      initializeGTM(userData);

      const auth0_roles = decoded[ROLES_NAMESPACE] || [];

      dispatch(
        storeUserAction({
          ...userData,
          auth0_roles,
        })
      );

      setUserToLocalStorage({
        ...userData,
        auth0_roles,
      });

      setEntitlements(userData.entitlements);
      // If user only has access to horizon we redirect the user inmediately (only if entitlements are available)
      if (
        userData.entitlements &&
        !userData.entitlements?.anzaClient?.access &&
        userData.entitlements?.horizon?.access
      ) {
        return window.location.replace(
          getConfiguration('REACT_APP_HORIZON_HOME_PAGE')
        );
      }
    } catch (error: unknown) {
      if ((error as { error: string }).error === 'login_required') {
        await sleep(500);
        signOutUser();
      }
      catchError({
        error,
        location: '/hooks/useAuth',
        method: 'getTokenAndSetUser',
      });
      throw error;
    }
  }, [roles]);

  const updateUser = useCallback(
    async (userData: UserType) => {
      try {
        const accessToken = await getAccessTokenSilently({
          audience: getConfiguration('REACT_APP_AUTH0_AUDIENCE'),
          scope: getConfiguration('REACT_APP_AUTH0_SCOPES'),
          ignoreCache: true,
        });

        dispatch(
          storeUserAction({
            ...user,
            ...userData,
            token: accessToken,
          })
        );

        setUserToLocalStorage({
          ...user,
          ...userData,
        });
      } catch (error) {
        catchError({ error, location: '/hooks/useAuth', method: 'updateUser' });
      }
    },
    [user]
  );

  const authorizeUser = async () => {
    const [logoutCookie] = getLogoutCookie();

    if (logoutCookie) {
      deleteLogoutCookie();
    }

    setIsAuthorized(false);
    try {
      await getTokenAndSetUser();
    } catch (error: unknown) {
      if (
        (error as ErrorType).response &&
        (error as ErrorType).response.data.message === 'Unauthenticated'
      ) {
        if (!(error as ErrorType).response.data.errorData?.email_verified) {
          throw Error(Errors.EMAIL_NOT_VERIFIED);
        }

        throw Error(Errors.NOT_ALLOWED);
      }

      throw error;
    } finally {
      setIsAuthorized(true);
    }
  };

  return {
    signOutUser,
    authorizeUser,
    getAccessTokenSilently,
    loginWithRedirect,
    updateUser,
    user,
    isAuthenticated,
    isLoading,
    isAuthorized,
    entitlements,
  };
};

export default useAuth;
