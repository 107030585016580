import React, { useMemo, useState } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getConfiguration } from '~/utils/configurations';

import { gaPageView, isUserAdmin, isUserCustomer, isUserVendor } from '~/utils';
import useAuth, { KEYS } from '~/hooks/useAuth';
import {
  loadHsScript,
  createHsWindow,
  setHsPath,
  trackHsPageView,
  identifyHsVisitorByEmail,
} from '~/utils/hubspot';
import { OverlayLoader } from '~/UI/Overlay';
import ErrorBoundary from '~/utils/ErrorBoundary';
import { removePageBanner as removePageBannerAction } from '~/store/ui';
import { useAppDispatch } from '~/store/hooks';
import { useFlags } from '~/utils/launchdarkly';

import {
  ModelRoute,
  ROUTES,
  projectRoutes,
  routes as defaultRoutes,
  vendorRoutes,
} from './routes';
import PrivateRoute from './PrivateRoute';
import { ROUTER_PATHS } from './Paths';
import { UserType } from '~/types/users';

export type RedirectConfig = {
  /** url to redirect */
  redirect: string;
  /** true if the redirect url belongs to an external app/page. False if the redirect url (relative) belong to this app  */
  external: boolean;
};

const getUserLandingPageRedirectConfig = (
  user: UserType,
  isMaintenanceModeOn: boolean
): RedirectConfig => {
  if (isUserAdmin(user)) {
    return {
      redirect: ROUTER_PATHS.ADMIN(),
      external: true,
    };
  }
  if (isMaintenanceModeOn) {
    return {
      external: false,
      redirect: ROUTER_PATHS.MAINTENANCE(),
    };
  }
  if (isUserVendor(user)) {
    return {
      external: false,
      redirect: ROUTER_PATHS.vendors.PIPELINE(),
    };
  }
  if (isUserCustomer(user)) {
    if (
      user?.entitlements && // we only redirect if the user has the entitlements object
      user?.entitlements?.horizon?.access
    ) {
      // redirect to horizon
      return {
        redirect: getConfiguration('REACT_APP_HORIZON_HOME_PAGE'),
        external: true,
      };
    }

    return {
      redirect: ROUTER_PATHS.projects.PROJECTS(),
      external: false,
    };
  }
  return {
    redirect: '/',
    external: false,
  };
};

const Router: React.FC = () => {
  const {
    isLoading,
    authorizeUser,
    isAuthorized,
    getAccessTokenSilently,
    isAuthenticated,
    user,
    entitlements,
  } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const ldClient = useLDClient();
  const flags = useFlags();
  const [isMaintenanceModeOn, setIsMaintenanceModeOn] = useState<
    boolean | undefined
  >(undefined);
  React.useEffect(() => {
    createHsWindow();
    loadHsScript();
    dispatch(removePageBannerAction());
  }, []);

  const routes = useMemo(
    () =>
      defaultRoutes.filter((item) => {
        if (
          item.id === ROUTES.PRICING_HISTORY.id &&
          (!flags.solarPricingVisualization || !user.company?.engaged_solar)
        ) {
          return false;
        }
        return true;
      }),
    [flags.solarPricingVisualization]
  );

  React.useEffect(() => {
    gaPageView({ page: location.pathname });

    if (user && user.email) {
      identifyHsVisitorByEmail(user.email);

      if (getConfiguration('REACT_APP_DEPLOYMENT_ENV') === 'test') {
        ldClient?.identify({ key: 'qa@anzarenewables.com', kind: 'user' });
      } else {
        ldClient?.identify({ key: user.email, kind: 'user' });
      }
    }

    setHsPath(location.pathname);
    trackHsPageView();
  }, [location]);

  React.useEffect(() => {
    (async () => {
      try {
        setIsMaintenanceModeOn(flags.maintenanceMode);

        if (location.pathname !== ROUTES.AUTH.path() && !isLoading) {
          await authorizeUser();
        }
      } catch (e: any) {
        localStorage.setItem(KEYS.INTENDED_ROUTE, location.pathname);
        sessionStorage.setItem(KEYS.INTENDED_SEARCH, location.search);
        history.push(ROUTES.AUTH.path());
      }
    })();
  }, [isLoading, getAccessTokenSilently, flags]);

  const isUserAuthenticationAndRetrievalPending =
    (isLoading || !isAuthorized || (isAuthenticated && !user.id)) &&
    location.pathname !== ROUTES.AUTH.path();

  const waitingForAnzaClientAccess =
    entitlements &&
    !entitlements?.anzaClient?.access &&
    location.pathname !== ROUTES.AUTH.path();

  if (
    waitingForAnzaClientAccess ||
    isUserAuthenticationAndRetrievalPending ||
    isMaintenanceModeOn === undefined
  ) {
    return <OverlayLoader />;
  }

  const routesMapper = (modelRoute: ModelRoute[]) =>
    modelRoute.map(
      ({
        id,
        path,
        exact = false,
        component,
        authenticated,
        roles,
        title,
        layout,
        contentBackHandler,
        navbarBackHandler,
        hideNavBarMainItems,
        hideNavBarSecondaryItems,
        hideNavBarRightMenuItems,
        gtmId,
      }) => {
        const props = {
          path: path(),
          roles,
          component,
          exact,
          id,
          title,
          layout,
          contentBackHandler,
          navbarBackHandler,
          hideNavBarMainItems,
          hideNavBarSecondaryItems,
          hideNavBarRightMenuItems,
          gtmId,
        };
        return authenticated ? (
          <PrivateRoute key={id} {...props} />
        ) : (
          <Route key={id} {...props} />
        );
      }
    );

  const renderRoutes = () => {
    if (isUserAdmin(user)) {
      return routesMapper([...routes, ...projectRoutes, ...vendorRoutes]);
    }

    // FOR MAINTENANCE ONLY!
    if (isMaintenanceModeOn) {
      return routesMapper([ROUTES.MAINTENANCE]);
    }

    if (isUserCustomer(user)) {
      return routesMapper([...routes, ...projectRoutes]);
    }

    if (isUserVendor(user)) {
      return routesMapper([...routes, ...vendorRoutes]);
    }

    return routesMapper(routes);
  };

  const pageNotFoundOrMaintenanceModeRoute = isMaintenanceModeOn
    ? ROUTER_PATHS.MAINTENANCE()
    : ROUTER_PATHS.PAGE_NOT_FOUND();

  const landingPageRedirectConfig = getUserLandingPageRedirectConfig(
    user,
    isMaintenanceModeOn
  );

  return (
    <ErrorBoundary>
      <Switch>
        {!flags.marketIntelVisualization && (
          <Route
            path="/vendor/market-intelligence"
            exact
            component={
              landingPageRedirectConfig.external
                ? () => {
                    window.location.href = landingPageRedirectConfig.redirect;
                    return null;
                  }
                : undefined
            }
          >
            <Redirect to={pageNotFoundOrMaintenanceModeRoute} />
          </Route>
        )}
        {renderRoutes()}
        <Route
          path="/"
          exact
          component={
            landingPageRedirectConfig.external
              ? () => {
                  window.location.href = landingPageRedirectConfig.redirect;
                  return null;
                }
              : undefined
          }
        >
          {!landingPageRedirectConfig.external && (
            <Redirect to={landingPageRedirectConfig.redirect} />
          )}
        </Route>
        <Route
          render={() => <Redirect to={pageNotFoundOrMaintenanceModeRoute} />}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default Router;
