import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Pako from 'pako';
import { ascend, sortWith, values } from 'ramda';
import { base64ToUint8Array } from 'uint8array-extras';
import { routes } from '~/api/routes';
import { ANZA_ENGINE_RUN_STATUS } from '~/constants/engine';
import { prepareHeaders, BASE_URL_V2 } from '~/store/api/settings';
import {
  StorageEngineResult,
  StorageEngineResultType,
  StorageEngineTransformedResultType,
} from '~/store/project';
import { getStorageRankingWithCalculatedValues } from '~/utils/engineOutput';

export const engineApi = createApi({
  reducerPath: 'engineApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL_V2,
    prepareHeaders,
  }),
  keepUnusedDataFor: 1,
  tagTypes: ['StorageEngineResults', 'SolarEngineResults'],
  endpoints: (builder) => ({
    getStorageEngineResults: builder.query<
      StorageEngineTransformedResultType,
      { id: string; engineVersion: string }
    >({
      query: ({ id, engineVersion }) =>
        routes.engine.getStorageEngineResults(id, engineVersion),
      transformResponse: (response: StorageEngineResultType) => {
        const {
          engine,
          projectId,
          engineInputData,
          discountRatePercent,
          analytics_data_schema,
          status,
        } = response;
        if (status !== ANZA_ENGINE_RUN_STATUS.COMPLETED) {
          return {
            status,
            analytics_data_schema: {},
            projectId,
            storageRanking: [],
          };
        }

        const decodedEngine = base64ToUint8Array(engine);
        const inflatedEngine = Pako.inflate(decodedEngine, { to: 'string' });
        const engineObject = JSON.parse(inflatedEngine) as StorageEngineResult;
        const storageRanking = getStorageRankingWithCalculatedValues(
          values(engineObject),
          engineInputData,
          discountRatePercent
        );
        const sortedRanking = sortWith(
          [ascend((module) => module.lifecycleCost)],
          storageRanking
        );
        return {
          projectId,
          storageRanking: sortedRanking,
          analytics_data_schema,
          status,
        };
      },
      providesTags: (result, error, { id }) => [
        { type: 'StorageEngineResults', uuid: id },
      ],
    }),
  }),
});

export const { useGetStorageEngineResultsQuery } = engineApi;
