import * as R from 'ramda';
import { AxiosResponse } from 'axios';
import { SearchInputs } from 'types/projects';
import { api, apiV2 } from '../../api/axios';
import { routes } from '../../api/routes';
import { catchError } from '../../utils';

export interface AnzaLocationsListItem {
  id: string;
  city: string;
  state: string;
  text?: string;
}

export interface GetModulesResponse {
  success: boolean;
  data: Array<ModulesData>;
}

export interface GetCompanyModulesResponse {
  success: boolean;
  data: Array<CompanyModulesData>;
}

interface ModulesData {
  uuid: string;
  company?: string;
  name: string;
  watts: number;
  npv: number;
  rank: number;
  npvDelta: number;
  projectValueDelta: number;
  epcDelta: number;
  modWwMargin: number;
}

export interface CompanyModulesData {
  id: number;
  uuid: string;
  name: string;
  watts: number;
  efficiency: number;
  company_name: string;
  voc: number;
  temperature_coeffient_voltage: number;
  length: number;
  width: number;
  site_shipping_standard_adder: number;
  site_shipping_high_adder: number;
  west_coast_port_adder: number;
  midwest_coast_port_adder: number;
  yearly_degradation: string;
  quarters: Array<QuartersData>;
  ownership_status: 'vendor' | 'inventory' | 'purchaseOrder';
  is_anza_deactivated: boolean;
  is_deactivated: boolean;
  updated_at: Date;
}

export interface QuartersData {
  availability_mw: number | string;
  unavailability_reason: string | null;
  ddp_east_coast_port: number | string;
  module_pricing_id: number;
  id: number;
  year: number;
  quarter: number;
}

interface ProjectPayload {
  arrival_date: string;
  location_id: string;
  project_address: string;
  racking_type: string;
  size_in_kw: number;
  user_id: string;
}

export interface TopRankingsProject {
  deliveryQuarter: number;
  deliveryYear: number;
  port: string;
  rackingType: string;
  region: string;
  shipping: string;
  sizeKw: number;
  status: string;
}

export interface TopRankingsModule {
  uuid: string;
  availabilityMw: number;
  ddpPrice: number;
  name: string;
  portAdder: number;
  price: number;
  priceToGetRank: { [key: number]: number };
  rank: number;
  shippingAdder: number;
}

interface GetProjectTopRankingsResponse {
  success: boolean;
  data: {
    modules: TopRankingsModule[];
    project: TopRankingsProject;
    lastRankingAt: Date;
  };
}

export const getLocations = async (): Promise<
  AxiosResponse<{ data: AnzaLocationsListItem[] }>
> => {
  try {
    return await api.get(routes.anza.locations());
  } catch (error) {
    catchError({
      error,
      method: 'getLocations',
      location: 'services/api/anza',
    });
    throw error;
  }
};

export const getModules = async (
  data: SearchInputs
): Promise<AxiosResponse<GetModulesResponse>> => {
  try {
    return await api.post(routes.anza.modules(), data);
  } catch (error) {
    catchError({
      error,
    });
    throw error;
  }
};

export const saveProject = async (data: ProjectPayload): Promise<string> => {
  return api
    .post(`${routes.admin.projects()}/new`, data)
    .then(R.pathOr('', ['data', 'data', 'uuid']));
};

export const saveProjectFile = async (
  file: File
): Promise<AxiosResponse<{ data: { fileId: string } }>> => {
  const formData = new FormData();
  formData.append('file', file);
  return api.post(routes.userProject.uploadFile(), formData);
};

export const updateCompanyModules = async (
  uuid: string,
  data: Partial<CompanyModulesData>
): Promise<AxiosResponse<GetCompanyModulesResponse>> => {
  try {
    return await api.patch(routes.anza.company.updateModule(uuid), data);
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export const getProjectTopRankings = async (
  projectUuid: string,
  vendorUuid: string
): Promise<AxiosResponse<GetProjectTopRankingsResponse>> => {
  try {
    return await apiV2.get(
      routes.anza.project.topRankings(projectUuid, vendorUuid)
    );
  } catch (error) {
    catchError({ error });
    throw error;
  }
};

export interface PipelineDataObject {
  id: string;
  label: string;
  value: string | number;
  count: number;
}

export interface PipelineDataStruct {
  [key: string]: Array<PipelineDataObject>;
}

export interface PipelineDataSet {
  [key: string]: PipelineDataValues;
}

export interface PipelineData {
  [key: string]: number | string | PipelineDataSet;
}

export interface ChartDataStructureFromAPIModel {
  id: string;
  label: string;
  value: number;
  count: number;
}

export interface PipelineDataValues {
  totalMw: number;
  count: number;
}

export interface VendorPipelineResponse {
  success: boolean;
  data: PipelineData;
}

export interface ActiveProjectsResponse {
  success: boolean;
  data: Array<ActiveProjectsData>;
}

export const getVendorPipeline = async (): Promise<
  AxiosResponse<VendorPipelineResponse>
> => {
  try {
    return await apiV2.get(routes.anza.metrics.pipeline());
  } catch (error) {
    catchError({
      error,
      method: 'getVendorPipeline',
      location: 'services/api/anza',
    });
    throw error;
  }
};

export const getModuleMetrics = async (
  vendorId: string,
  moduleId: string
): Promise<AxiosResponse<VendorPipelineResponse>> => {
  try {
    return await apiV2.get(
      routes.anza.metrics.vendorModules(vendorId, moduleId)
    );
  } catch (error) {
    catchError({
      error,
      method: 'getModuleMetrics',
      location: 'services/api/anza',
    });
    throw error;
  }
};

export interface QuarterData {
  year: number;
  quarter: number;
}

interface ModuleData {
  uuid: string;
  name: string;
  rank: number;
  topRankDelta: number | null;
  vendorId?: string;
}

export interface ActiveProjectsData {
  uuid: string;
  projectSizeKw: number;
  quarterSelection: QuarterData;
  region: 'Midwest' | 'West' | 'South' | 'Northeast';
  rackingType: 'Fixed Tilt' | 'Single Axis Tracker';
  topModule: ModuleData | undefined;
  createdAt: string;
  anticipatedPurchaseDate: string;
}

export const getVendorPipelineTable = async (
  vendorId: string
): Promise<AxiosResponse<ActiveProjectsResponse>> => {
  try {
    return await apiV2.get(routes.anza.metrics.vendorProjects(vendorId));
  } catch (error) {
    catchError({
      error,
      method: 'getVendorPipelineTable',
      location: 'services/api/anza',
    });
    throw error;
  }
};
/**
 * Storage Modules types and services
 */

export interface AnzaStorageProject {
  uuid: string;
  projectName: string | null;
  name: string | null;
  projectAddress: string | null;
  date: Date;
  targetAcPowerMW: number;
  duration: number;
  powerFactor: number;
  meteringLocation: string;
  projectTopology: string;
  annualCycleCount: number;
  maintenanceTerm: number;
  layoutFile: File | null;
  latitude: number;
  longitude: number;
  googlePlaceId: string;
  customFile?: File;
  storageArrivalDate?: string;
  project_type: 'storage' | 'solar';
}

export interface StorageModulesData {
  index?: number;
  key?: string | number;
  uuid: string;
  pcs: string;
  pcsManufacturer: string;
  dcBlock: string;
  dcBlockManufacturer: string;
  dcBlockId: string;
  pcsCount: number;
  dcBlockCount: number;
  dcBlockPerPcs: number[][];
  dcEnergyKwh: number;
  footprintsM2: number | null;
  dcBlockCost: number;
  pcsCost: number;
  capex: number;
  capexPerKwh: number;
  capexSaving: number;
  addedPCS: number[];
  children?: Array<StorageModulesData>;
}

export interface GetStorageModulesResponse {
  success: boolean;
  data: Array<StorageModulesData>;
}

export interface GetStorageProjectResponse {
  success: boolean;
  data: AnzaStorageProject;
}

export type StorageModulesInputs = AnzaStorageProject;

export const processPanFile = async (
  filePath: string,
  fileType: string,
  ignoreLidLoss?: boolean
): Promise<AxiosResponse> => {
  try {
    return await apiV2.get(
      routes.admin.parsers.parsePanFile(filePath, fileType, ignoreLidLoss)
    );
  } catch (error) {
    catchError({ error });
    throw error;
  }
};
