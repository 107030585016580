import React, { useState } from 'react';
import * as R from 'ramda';
import styled from '@emotion/styled';
import { ColumnsType } from 'antd/lib/table';

import { ButtonAnt as Button } from '~/UI';
import RadioButtonsGroup from '~/UI/RadioButtonsGroup';
import StateCard from '~/components/StateCard';
import { StorageProject, StorageRanking } from '~/store/project';
import { isUserAdmin, theme, MAILTO, projectIsClosed } from '~/utils';
import {
  TEST_DATA_COMPONENTS,
  TEST_DATA_PAGES,
  TestIdProps,
} from '~/utils/dataTestProps';
import { STORAGE_TABLES } from '~/constants/rankingTable';
import useAuth from '~/hooks/useAuth';
import { useGetStorageColumns } from '~/hooks/useGetStorageColumns';
import { PROJECT_STATUSES } from '~/constants/projects';

import TableComponent from './TableComponent';
import StorageEngagedEmptyState from './StorageEngagedEmptyState';
import StorageNoModulesAvailableState from './StorageNoModulesAvailableState';

const dataTestIdConfig: TestIdProps = {
  page: TEST_DATA_PAGES.CUSTOMER.STORAGE_PROJECT_DETAILS,
  component: TEST_DATA_COMPONENTS.BUTTON,
};

export type IndexedStorageRanking = StorageRanking & {
  index: number;
  key: string;
};

type StorageModuleTableProps = {
  project: StorageProject;
  storageRanking: IndexedStorageRanking[];
  isLoading: boolean;
  handleOnOpenDrawer: () => void;
  showDetailTabAsRedacted: boolean;
  showEngagedEmptyState: boolean;
  enableCSVSummaryDownload: boolean;
  storageEngineVersion: string;
};

export type EngineResultSizingArchitectureRow = IndexedStorageRanking & {
  index: number;
  key: string;
  children?: Array<IndexedStorageRanking & { index: number }>;
};

type TableOption = keyof typeof STORAGE_TABLES;

const TableContainer = styled.div`
  background-color: ${theme.colors.background};
  border-radius: 8px;
  border: 1px solid ${theme.colors.gray200};
  margin-top: 20px;
`;

const tableOptions: { label: string; value: TableOption }[] = [
  {
    label: 'Overview',
    value: 'STORAGE_RANKING_TABLE',
  },
  {
    label: 'Lifecycle Costs',
    value: 'LIFECYCLE_COST',
  },
  {
    label: 'BOL Architecture',
    value: 'BOL_ARCHITECTURE',
  },
  {
    label: 'Capacity Maintenance',
    value: 'CAPACITY_MAINTENANCE',
  },
];

const groupDataByDCBlock = (modules: IndexedStorageRanking[]) => {
  const groupedData: EngineResultSizingArchitectureRow[] = [];
  modules.forEach((el, index) => {
    const group = groupedData.find((m) => m.dc_block.UUID === el.dc_block.UUID);
    if (group) {
      return group.children?.push(el);
    }
    return groupedData.push({
      ...el,
      children: [],
      index,
      key: `${el.dc_block.UUID} ${el.pcs_module.UUID}`,
    });
  });
  return groupedData;
};

const StorageModuleTable = ({
  storageRanking,
  project,
  isLoading,
  handleOnOpenDrawer,
  showDetailTabAsRedacted,
  showEngagedEmptyState: showEngagedEmptyStateProp,
  enableCSVSummaryDownload,
  storageEngineVersion,
}: StorageModuleTableProps) => {
  const { user } = useAuth();
  const [selectedTable, setSelectedTable] = useState<TableOption>(
    'STORAGE_RANKING_TABLE'
  );

  const isTableDataEmpty = R.isEmpty(storageRanking);

  const projectStatus = project.project_details.status;
  const isStorageRankingTable = selectedTable === 'STORAGE_RANKING_TABLE';
  const visibleTableStates = [
    PROJECT_STATUSES.created.key,
    PROJECT_STATUSES.proposal.key,
    PROJECT_STATUSES.awarded.key,
  ].includes(projectStatus);
  const showWillBeInTouchState =
    !isStorageRankingTable &&
    projectStatus === 'created' &&
    !isUserAdmin(user) &&
    !isLoading;
  const showInactiveState =
    projectIsClosed(projectStatus) && !isUserAdmin(user);

  const showNoModulesAvailable = !showWillBeInTouchState && isTableDataEmpty;

  const showSelectedTable =
    (visibleTableStates || isUserAdmin(user)) &&
    !showInactiveState &&
    !isLoading;

  const storageTablesWithFilter = useGetStorageColumns(
    storageRanking,
    {
      redacted: showDetailTabAsRedacted,
      enableDownload: enableCSVSummaryDownload,
    },
    storageEngineVersion
  );

  const showEngagedEmptyState =
    showEngagedEmptyStateProp &&
    !isStorageRankingTable &&
    !isUserAdmin(user) &&
    !isLoading &&
    (projectStatus === 'proposal' || projectStatus === 'awarded');

  const showPlaceholder = showEngagedEmptyState || showNoModulesAvailable;

  const data = showPlaceholder ? [] : groupDataByDCBlock(storageRanking);

  return (
    <TableContainer>
      <RadioButtonsGroup
        style={{
          padding: '16px',
        }}
        defaultValue="STORAGE_RANKING_TABLE"
        onChange={(e) => setSelectedTable(e.target.value)}
        buttonStyle="solid"
        options={tableOptions}
      />
      {showInactiveState && (
        <StateCard
          type="inactive"
          title="This project is no longer active."
          subtitle="We apologize for the inconvenience. If you believe this to be an error, please contact your account manager."
        >
          <Button type="default">
            <a
              href={`mailto:${MAILTO}?subject=Anza - Help`}
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </Button>
        </StateCard>
      )}
      {showSelectedTable && (
        <TableComponent
          data={data}
          isLoading={isLoading}
          columns={storageTablesWithFilter[selectedTable] as ColumnsType}
          size="small"
          className="alternate-rows-even"
          scroll={{ x: '100%' }}
          pagination={false}
          rowKey="key"
          hideBorder
          locale={{
            emptyText: showPlaceholder && (
              <>
                {showNoModulesAvailable && (
                  <StorageNoModulesAvailableState
                    isLoading={isLoading}
                    handleOnOpenDrawer={handleOnOpenDrawer}
                    dataTestIdConfig={dataTestIdConfig}
                  />
                )}
                {showEngagedEmptyState && <StorageEngagedEmptyState />}
              </>
            ),
          }}
        />
      )}
    </TableContainer>
  );
};

export default StorageModuleTable;
