import React, { useEffect, useState, memo } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import * as R from 'ramda';

import {
  Form,
  InputNumber,
  InputAnt as Input,
  Option,
  FormGroup,
  FormItemLabel,
  FormInstance,
  Switch,
} from '~/UI';
import { PercentSettings, decimalFormatter } from '~/utils/formatters';
import { inRangeValidator } from '~/utils/numberValidators';
import { cellTypes } from '~/utils/constants';
import type { CheckInvalid } from '~/utils/getCheckInvalidFormFields';
import {
  BACKSIDE_GLASS_MM_OPTIONS,
  BACKSIDE_MATERIAL_OPTIONS,
  FRONTSIDE_GLASS_MM_OPTIONS,
  MAX_MECH_TEST_LOAD_SNOW_MAX,
  MAX_MECH_TEST_LOAD_SNOW_MIN,
  MAX_MECH_TEST_LOAD_WIND_MAX,
  MAX_MECH_TEST_LOAD_WIND_MIN,
  MODULE_TAGS,
} from '~/constants/modules';
import ModuleTag from '~/components/ModuleTag';
import { SelectFormItem } from '~/UI/Select';
import { notRequiredRules } from '~/utils';
import { ModuleConnectorTypesResponse } from '~/services/api/modules';

import {
  StyledButton,
  StyledDivider,
  StyledSpace,
} from './AdminModulesForm.styles';
import { Formatter } from '../../LocationModifiers/AdminLocationModifiersForm';

type ValidatorInputType = {
  firstSolarMin: number;
  firstSolarMax: number;
  defaultMin: number;
  defaultMax: number;
};

const firstSolarDependentValidator = (
  isFirstSolar: boolean | undefined,
  isRequired: boolean,
  { firstSolarMin, firstSolarMax, defaultMin, defaultMax }: ValidatorInputType
) =>
  isFirstSolar
    ? inRangeValidator(
        firstSolarMin,
        firstSolarMax,
        true,
        `Please enter a number between ${firstSolarMin} and ${firstSolarMax}`,
        isRequired,
        false
      ).validator
    : inRangeValidator(
        defaultMin,
        defaultMax,
        true,
        `Please enter a number between ${defaultMin} and ${defaultMax}`,
        isRequired,
        false
      ).validator;

const blankFormatter = (val: string) => val;
interface ModuleInformationFormGroupProps {
  requiredRule: {
    required: boolean;
    message: string;
  };
  isAnzaActive: boolean;
  connectorTypesData?: ModuleConnectorTypesResponse;
  form: FormInstance;
  checkInvalid: CheckInvalid;
  isFirstSolar?: boolean;
}

const ModuleInformationFormGroup: React.FC<ModuleInformationFormGroupProps> = ({
  isAnzaActive,
  requiredRule,
  connectorTypesData,
  form,
  isFirstSolar,
}) => {
  const isBifacial = Form.useWatch('is_bifacial');
  const bifacialityFactor = Form.useWatch('bifaciality_factor');
  const ulListed = Form.useWatch('ul_listed');
  const iecListed = Form.useWatch('iec_listed');
  const cecListed = Form.useWatch('cec_listed');
  const [tempCAlertVisible, setTempCAlertVisible] = useState<boolean>(false);
  const [newConnectorTypeName, setNewConnectorTypeName] = useState('');
  const [connectorTypeOptions, setConnectorTypeOptions] = useState<string[]>(
    []
  );

  useEffect(() => {
    const listedItems = {
      ul_listed: ulListed,
      iec_listed: iecListed,
      cec_listed: cecListed,
    };
    Object.entries(listedItems).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        form.setFieldValue(key, false);
      }
    });
  }, [ulListed, iecListed, cecListed]);

  useEffect(() => {
    if (!isBifacial || !bifacialityFactor) {
      form.setFieldValue('bifaciality_factor', 0);
    }
  }, [isBifacial, bifacialityFactor]);

  useEffect(() => {
    if ((connectorTypesData as string[])?.length)
      setConnectorTypeOptions(connectorTypesData as string[]);
  }, [connectorTypesData]);

  const handleOnClickAddItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!newConnectorTypeName) return;
    setConnectorTypeOptions([...connectorTypeOptions, newConnectorTypeName]);
    setNewConnectorTypeName('');
  };

  const handleOnChangeNewConnectorTypeName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewConnectorTypeName(event.target.value);
  };

  return (
    <>
      <FormGroup
        key="Mechanical"
        title="Mechanical"
        style={{ maxWidth: '100%' }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(auto, 49%))',
            gridTemplateRows: 'auto',
            gap: '1rem',
          }}
        >
          <Form.Item
            name="long_edge"
            label={<FormItemLabel>Long Edge</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  100,
                  10000,
                  false,
                  'Please enter an integer between 100 and 10000, ex. 120',
                  isAnzaActive,
                  true
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="long_edge"
              placeholder="Insert the length of the long edge"
              addonAfter="mm"
              formatter={blankFormatter as Formatter}
            />
          </Form.Item>
          <Form.Item
            name="short_edge"
            label={<FormItemLabel>Short Edge</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  100,
                  10001,
                  false,
                  'Please enter an integer between 100 and 10001, ex. 120',
                  isAnzaActive,
                  true
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="short_edge"
              placeholder="Insert the length of the short edge"
              addonAfter="mm"
              formatter={blankFormatter as Formatter}
            />
          </Form.Item>
          <Form.Item
            name="frame_width"
            label={<FormItemLabel>Frame Thickness</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  0,
                  200,
                  false,
                  'Please enter an integer between 0 and 200, ex. 120',
                  isAnzaActive,
                  true
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              placeholder="Insert the frame thickness of the module"
              addonAfter="mm"
              formatter={blankFormatter as Formatter}
            />
          </Form.Item>
          <Form.Item
            name="weight_kg"
            label={<FormItemLabel>Weight</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  0,
                  100,
                  false,
                  'Please enter value between 0 and 100, ex. 12',
                  isAnzaActive
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              placeholder="Insert the weight of the module"
              addonAfter="kg"
              precision={3}
            />
          </Form.Item>
          <SelectFormItem
            name="connector_type"
            label={<FormItemLabel>Connector Type</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={isAnzaActive ? [requiredRule!] : notRequiredRules}
            selectProps={{
              mode: 'multiple',
              maxTagTextLength: 60,
              placeholder: 'Select or add a connector type',
              options: connectorTypeOptions?.map((item: string) => ({
                label: item,
                value: item,
              })),
              dropdownRender: (menu) => (
                <>
                  {menu}
                  <StyledDivider />
                  <StyledSpace>
                    <Form.Item>
                      <Input
                        placeholder="Enter new item"
                        value={newConnectorTypeName}
                        onChange={handleOnChangeNewConnectorTypeName}
                      />
                    </Form.Item>
                    <StyledButton
                      type="text"
                      icon={<AiOutlinePlus />}
                      onClick={handleOnClickAddItem}
                    >
                      Add item
                    </StyledButton>
                  </StyledSpace>
                </>
              ),
            }}
          />
          <SelectFormItem
            name="lead_length"
            label={<FormItemLabel>Lead Length</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={isAnzaActive ? [requiredRule!] : notRequiredRules}
            selectProps={{
              mode: 'tags',
              tokenSeparators: [','],
              placeholder: 'Add a lead length',
              maxTagTextLength: 60,
            }}
          />
          <Form.Item
            name="fire_class_rating"
            label={<FormItemLabel>Fire Class Rating</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                validator: inRangeValidator(
                  1,
                  100,
                  true,
                  'Please enter an integer between 1 and 100, ex. 7',
                  false,
                  true
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="fire_class_rating"
              placeholder="Insert the fire class rating of the module"
              formatter={blankFormatter as Formatter}
            />
          </Form.Item>
          <SelectFormItem
            name="cell_type"
            label={<FormItemLabel>Cell type</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={isAnzaActive ? [requiredRule!] : notRequiredRules}
            selectProps={{
              placeholder: 'Select a cell type',
              showSearch: true,
            }}
          >
            {Object.values(cellTypes).map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </SelectFormItem>
          <Form.Item
            name="cell_count_short_dimension"
            label={<FormItemLabel>Cell Count Short Dimension</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  1,
                  20,
                  true,
                  'Please enter an integer between 1 and 20, ex. 7',
                  isAnzaActive,
                  true
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="cell_count_short_dimension"
              placeholder="Insert the cell count short dimension"
              formatter={blankFormatter as Formatter}
            />
          </Form.Item>
          <Form.Item
            name="cell_count_long_dimension"
            label={<FormItemLabel>Cell Count Long Dimension</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  10,
                  300,
                  true,
                  'Please enter an integer between 10 and 300, ex. 12',
                  isAnzaActive,
                  true
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="cell_count_long_dimension"
              placeholder="Insert the cell count long dimension"
              formatter={blankFormatter as Formatter}
            />
          </Form.Item>
          <SelectFormItem
            name="frontside_glass_mm"
            label={<FormItemLabel>Frontside Glass</FormItemLabel>}
            labelCol={{ span: 24 }}
            selectProps={{
              placeholder: 'Select...',
              options: FRONTSIDE_GLASS_MM_OPTIONS.map((mm) => {
                return {
                  value: mm,
                  label: `${decimalFormatter(mm, {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} mm`,
                };
              }),
            }}
          />
          <SelectFormItem
            name="backside_material"
            label={<FormItemLabel>Backside Material</FormItemLabel>}
            labelCol={{ span: 24 }}
            selectProps={{
              placeholder: 'Select...',
              options: R.values(BACKSIDE_MATERIAL_OPTIONS).map((opt) => ({
                value: opt,
                label: opt,
              })),
            }}
          />

          <SelectFormItem
            name="backside_glass_mm"
            label={<FormItemLabel>Backside Glass Thickness</FormItemLabel>}
            labelCol={{ span: 24 }}
            selectProps={{
              placeholder: 'Select...',
              options: BACKSIDE_GLASS_MM_OPTIONS.map((mm) => {
                if (mm === null) return { value: mm, label: 'N/A' };
                return {
                  value: mm,
                  label: `${decimalFormatter(mm, {
                    style: 'decimal',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} mm`,
                };
              }),
            }}
          />

          <Form.Item
            name="max_mech_test_load_snow"
            label={<FormItemLabel>Max Mech Test Load - Snow</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: false,
                validator: inRangeValidator(
                  MAX_MECH_TEST_LOAD_SNOW_MIN,
                  MAX_MECH_TEST_LOAD_SNOW_MAX,
                  true,
                  `Please enter an integer between ${MAX_MECH_TEST_LOAD_SNOW_MIN} and ${MAX_MECH_TEST_LOAD_SNOW_MAX}.`,
                  false,
                  true
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="max_mech_test_load_snow"
              formatter={blankFormatter as Formatter}
              addonAfter="Pa"
              placeholder="Insert Max Mech Test Load - Snow..."
            />
          </Form.Item>

          <Form.Item
            name="max_mech_test_load_wind"
            label={<FormItemLabel>Max Mech Test Load - Wind</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: false,
                validator: inRangeValidator(
                  MAX_MECH_TEST_LOAD_WIND_MIN,
                  MAX_MECH_TEST_LOAD_WIND_MAX,
                  true,
                  `Please enter an integer between ${MAX_MECH_TEST_LOAD_WIND_MIN} and ${MAX_MECH_TEST_LOAD_WIND_MAX}.`,
                  false,
                  true
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="max_mech_test_load_wind"
              formatter={blankFormatter as Formatter}
              addonAfter="Pa"
              placeholder="Insert Max Mech Test Load - Wind..."
            />
          </Form.Item>
        </div>
      </FormGroup>

      <FormGroup
        key="Electrical"
        title="Electrical"
        style={{ maxWidth: '100%' }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(auto, 49%))',
            gridTemplateRows: 'auto',
            gap: '1rem',
          }}
        >
          <Form.Item
            name="voc"
            label={<FormItemLabel>VOC</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: firstSolarDependentValidator(
                  isFirstSolar,
                  isAnzaActive,
                  {
                    firstSolarMin: 210,
                    firstSolarMax: 240,
                    defaultMin: 40,
                    defaultMax: 60,
                  }
                ),
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="voc"
              placeholder="Insert the VOC"
              precision={2}
              addonAfter="V"
            />
          </Form.Item>
          <Form.Item
            name="isc_amps"
            label={<FormItemLabel>Isc</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: firstSolarDependentValidator(
                  isFirstSolar,
                  isAnzaActive,
                  {
                    firstSolarMin: 2,
                    firstSolarMax: 4,
                    defaultMin: 10,
                    defaultMax: 25,
                  }
                ),
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="isc_amps"
              placeholder="Insert the Isc"
              precision={2}
              addonAfter="A"
            />
          </Form.Item>
          <Form.Item
            name="vmp_voltage"
            label={<FormItemLabel>Vmp</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: firstSolarDependentValidator(
                  isFirstSolar,
                  isAnzaActive,
                  {
                    firstSolarMin: 180,
                    firstSolarMax: 200,
                    defaultMin: 25,
                    defaultMax: 55,
                  }
                ),
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="vmp_voltage"
              placeholder="Insert the Vmp"
              precision={2}
              addonAfter="V"
            />
          </Form.Item>
          <Form.Item
            name="imp_amps"
            label={<FormItemLabel>Imp</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: firstSolarDependentValidator(
                  isFirstSolar,
                  isAnzaActive,
                  {
                    firstSolarMin: 2,
                    firstSolarMax: 4,
                    defaultMin: 10,
                    defaultMax: 25,
                  }
                ),
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="imp_amps"
              placeholder="Insert the Imp"
              precision={2}
              addonAfter="A"
            />
          </Form.Item>
          <Form.Item
            name="temperature_coeffient_isc"
            label={
              <FormItemLabel>Temperature Coefficient of Isc</FormItemLabel>
            }
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  0,
                  0.01,
                  true,
                  'Please enter a value between 0% and 1%, ex. 0.3%',
                  isAnzaActive
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="temperature_coeffient_isc"
              placeholder="Insert the temperature coefficient of Isc"
              stringMode
              addonAfter="%/°C"
              step={0.00001}
              precision={5}
              parser={PercentSettings.parser}
              formatter={PercentSettings.formatterWithFractionDigits(3)}
            />
          </Form.Item>
          <Form.Item
            name="temperature_coeffient_voltage"
            label={
              <FormItemLabel>Temperature Coefficient of Voltage</FormItemLabel>
            }
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  -0.01,
                  0,
                  true,
                  'Please enter a value between -1% and 0%, ex. -0.3%',
                  isAnzaActive
                ).validator,
              },
            ]}
            help={tempCAlertVisible ? 'Value is less than -1%' : undefined}
            validateStatus={tempCAlertVisible ? 'warning' : undefined}
            validateTrigger="onBlur"
          >
            <InputNumber
              onChange={(value: any) => {
                setTempCAlertVisible(
                  value?.valueOf() < -0.01 && value?.valueOf() >= -0.05
                );
              }}
              name="temperature_coeffient_voltage"
              placeholder="Insert the temperature coefficient of voltage"
              stringMode
              addonAfter="%/°C"
              step={0.00001}
              precision={5}
              parser={PercentSettings.parser}
              formatter={PercentSettings.formatterWithFractionDigits(3)}
            />
          </Form.Item>
          <Form.Item
            name="temperature_coeffient_pmax"
            label={
              <FormItemLabel>Temperature Coefficient of Pmax</FormItemLabel>
            }
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  -0.01,
                  0,
                  true,
                  'Please enter a value between -1% and 0%, ex. -0.3%',
                  isAnzaActive
                ).validator,
              },
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="temperature_coeffient_pmax"
              placeholder="Insert the temperature coefficient of Pmax"
              stringMode
              addonAfter="%/°C"
              step={0.00001}
              precision={5}
              parser={PercentSettings.parser}
              formatter={PercentSettings.formatterWithFractionDigits(3)}
            />
          </Form.Item>
          <Form.Item
            name="bifaciality_factor"
            label={<FormItemLabel>Bifaciality Factor</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              ...(isBifacial
                ? [
                    {
                      required: isBifacial,
                      validator: inRangeValidator(
                        0,
                        1,
                        true,
                        'Please enter a value between 0 and 100, ex. 70%',
                        isBifacial,
                        false
                      ).validator,
                    },
                  ]
                : notRequiredRules),
            ]}
            validateTrigger="onBlur"
          >
            <InputNumber
              name="bifaciality_factor"
              placeholder="Insert the bifaciality factor"
              defaultValue={0}
              disabled={!isBifacial}
              parser={PercentSettings.parser}
              formatter={PercentSettings.formatterWithFractionDigits(2)}
              addonAfter="%"
            />
          </Form.Item>
        </div>
      </FormGroup>

      <FormGroup
        key="Approvals and Certificates"
        title="Approvals and Certificates"
        style={{ maxWidth: '100%' }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(auto, 49%))',
            gridTemplateRows: 'auto',
            gap: '1rem',
          }}
        >
          <Form.Item
            name="ul_listed"
            label={<FormItemLabel>UL Listed (61730)</FormItemLabel>}
            labelCol={{ span: 24 }}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="iec_listed"
            label={<FormItemLabel>IEC listed (61215)</FormItemLabel>}
            labelCol={{ span: 24 }}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="cec_listed"
            label={<FormItemLabel>CEC Listed</FormItemLabel>}
            labelCol={{ span: 24 }}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </div>
      </FormGroup>

      <FormGroup key="Warranty" title="Warranty" style={{ maxWidth: '100%' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(auto, 49%))',
            gridTemplateRows: 'auto',
            gap: '1rem',
          }}
        >
          <Form.Item
            name="warrantied_year_one_degradation"
            label={<FormItemLabel>Warrantied Year 1 Degradation</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  0,
                  0.09999,
                  true,
                  'Please enter a value between 0 and 10, ex. 0.005%',
                  isAnzaActive
                ).validator,
              },
            ]}
          >
            <InputNumber
              name="warrantied_year_one_degradation"
              placeholder="Insert the Warrantied Year 1 Degradation"
              stringMode
              parser={PercentSettings.parser}
              formatter={PercentSettings.formatterWithFractionDigits(3)}
              precision={5}
              addonAfter="%"
            />
          </Form.Item>
          <Form.Item
            name="yearly_degradation"
            label={<FormItemLabel>Warrantied Annual Degradation</FormItemLabel>}
            labelCol={{ span: 24 }}
            rules={[
              {
                required: isAnzaActive,
                validator: inRangeValidator(
                  0,
                  0.01999,
                  true,
                  'Please enter a value between 0 and 2, ex. 0.005%',
                  isAnzaActive
                ).validator,
              },
            ]}
          >
            <InputNumber
              name="yearly_degradation"
              placeholder="Insert the Warrantied Annual Degradation"
              step={0.01}
              parser={PercentSettings.parser}
              formatter={PercentSettings.formatterWithFractionDigits(3)}
              precision={5}
              stringMode
              addonAfter="%"
            />
          </Form.Item>
          <Form.Item
            name="performance_warranty"
            label={<FormItemLabel>Performance Warranty</FormItemLabel>}
            labelCol={{ span: 24 }}
            required={isAnzaActive}
            rules={[
              {
                validator: inRangeValidator(
                  0,
                  100,
                  true,
                  'Please enter an integer value between 0 and 100, ex. 30',
                  isAnzaActive,
                  true
                ).validator,
              },
            ]}
          >
            <InputNumber
              name="performance_warranty"
              placeholder="Insert the Performance Warranty"
              addonAfter="Years"
            />
          </Form.Item>
        </div>
      </FormGroup>
    </>
  );
};

export default memo(ModuleInformationFormGroup);
