import React from 'react';
import styled from '@emotion/styled';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Checkbox, Form } from '~/UI';

import { colors, fonts } from '~/utils';
import {
  GTM_EVENTS,
  GTM_SOURCE_TYPES,
  maybeSendPlatformEventToGTM,
} from '~/utils/gtm';

import { FilterConfig } from '../Filters/FilterForm';

export const StyledCheckBoxGroup = styled(Checkbox.Group)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .ant-checkbox-wrapper {
    margin-left: 0;
    font-weight: 400;
    font-size: ${fonts.sizes.button};
    line-height: 22px;
    color: ${colors.gray800};
    max-width: 100%;
    white-space: normal;
    text-align: left;
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  .ant-checkbox-disabled + span {
    color: ${colors.gray400};
  }
  .ant-checkbox-inner {
    border-radius: 2px;
    border: 2px solid ${colors.gray600};
    min-width: 18px;
    min-height: 18px;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-radius: 2px;
    border: 2px solid ${colors.primary};
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: transparent;
  }
`;

export type Option = {
  label: React.ReactNode;
  value: string;
  dataTestId?: string;
};

export type FilterProps = {
  filter: FilterConfig;
  includeGtmProperties?: boolean;
  className?: string;
};

const DOWN_DEPOSIT_FILTER_NAME = 'spa_down_deposit_qc';
const PAYMENT_TERMS_FILTER_NAME = 'spa_payment_terms';
const FIRE_CLASS_RATING_FILTER_NAME = 'fire_class_rating';

const getSortedOptions = (name: string, options: Option[]): Option[] => {
  let sortedOptions = options;

  if (
    [DOWN_DEPOSIT_FILTER_NAME, FIRE_CLASS_RATING_FILTER_NAME].includes(name)
  ) {
    sortedOptions = options.sort((a: Option, b: Option) => {
      const aKey = parseInt(a.value, 10);
      const bKey = parseInt(b.value, 10);

      if (Number.isNaN(aKey) || Number.isNaN(bKey)) {
        return 0;
      }

      return aKey - bKey;
    });
  }

  if (name === PAYMENT_TERMS_FILTER_NAME) {
    // expected format: "10% / 30% / 60% / 0%"
    const expectedFormat = /^(\d+(\.\d+)?% \/ ){3}\d+(\.\d+)?%$/;
    sortedOptions = options.sort((a: Option, b: Option) => {
      if (!expectedFormat.test(a.value) || !expectedFormat.test(b.value)) {
        return 0;
      }

      const aKeyString = a.value.split(' / ')[0].replace('%', '');
      const bKeyString = b.value.split(' / ')[0].replace('%', '');

      const aKey = parseInt(aKeyString, 10);
      const bKey = parseInt(bKeyString, 10);

      if (Number.isNaN(aKey) || Number.isNaN(bKey)) {
        return 0;
      }

      return aKey - bKey;
    });
  }

  return sortedOptions;
};

const Filter = ({
  filter,
  includeGtmProperties = false,
  className,
}: FilterProps) => {
  const { name, options } = filter;
  const sortedOptions = getSortedOptions(name, options);

  return (
    <Form.Item className={className} name={name} labelCol={{ span: 24 }}>
      <StyledCheckBoxGroup>
        {sortedOptions.map(({ label, value, dataTestId }) => {
          const infoFilterAnalytics = includeGtmProperties
            ? {
                'data-gtm-type': GTM_SOURCE_TYPES.FILTER,
                'data-gtm-id': value,
              }
            : {};
          return (
            <div key={value} {...infoFilterAnalytics}>
              <StyledCheckbox
                value={value}
                data-testid={dataTestId}
                onChange={
                  includeGtmProperties
                    ? (e: CheckboxChangeEvent) => {
                        const event = e.target.checked
                          ? GTM_EVENTS.CHECK_BOX_CHECKED
                          : GTM_EVENTS.CHECK_BOX_UNCHECKED;
                        maybeSendPlatformEventToGTM(event, value);
                      }
                    : undefined
                }
              >
                {label}
              </StyledCheckbox>
            </div>
          );
        })}
      </StyledCheckBoxGroup>
    </Form.Item>
  );
};

export default Filter;
