import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import * as R from 'ramda';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { Table, Typography } from '~/UI';
import { Box } from '~/components/Modules';
import {
  ActiveProjectsData,
  PipelineData,
  PipelineDataObject,
  PipelineDataValues,
} from '~/services/api/anza';
import useAuth from '~/hooks/useAuth';
import { ROUTER_PATHS } from '~/router/Paths';
import NoDataAvailable from '~/components/NoDataAvailable';
import { usePersistTableValues } from '~/hooks/usePersistTableValues';
import {
  useGetModulesQuery,
  useGetAllModulesQuery,
  useGetVendorPipelineQuery,
  useGetModuleMetricsQuery,
  useGetActiveProjectsQuery,
  useGetAllModuleMetricsQuery,
  useGetAllActiveProjectsQuery,
} from '~/store/api/vendor/vendorApi';
import {
  getMilliSecondsInDays,
  getMilliSecondsInHours,
} from '~/utils/timeConversions';
import {
  convertMwToGw,
  DATA_SET,
  processMetricData,
  getEmptyDataSet,
  getSortOrderValue,
} from '~/utils/dataProcessor';
import { CompanyType } from '~/types/users';
import { catchError, COMPANY_TYPES, isUserAdmin, theme } from '~/utils';
import { ArrowCircleRight } from '~/UI/Icons/ArrowCircleRight';
import Page from '~/router/Page';
import { useLazyGetAllCompaniesQuery } from '~/store/api';
import { RegionalData } from '~/UI/Metrics/GeoMapUnitedStates';
import { getColumns, ProjectResumeColumn } from './activeProjectsTableColumns';
import ModuleMetrics, { Metrics } from './ModuleMetrics';
import Pipeline from './Pipeline';

const containerLayoutStyles: React.CSSProperties = {
  display: 'grid',
  gridTemplateColumns: '1fr 3fr',
  gridTemplateRows: '1fr',
  gridGap: '1rem',
  margin: '0 auto',
  paddingTop: '1rem',
  paddingBottom: '1rem',
};

const MetricsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 245px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 8px;
`;

const ProjectsTable = styled(Table)<{ isAdmin?: boolean }>`
  .ant-table-thead > tr > th {
    padding-left: 30px;
    padding-right: 30px;
    background: #ffffff;
    font-weight: 600;
    font-size: 16px;
  }

  .ant-table-tbody > tr > td {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
  }

  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: #ffffff;
  }

  .on-row-hover {
    display: none;
  }

  .ant-table-thead th.ant-table-column-has-sorters:hover,
  .ant-table-tbody > tr > td.ant-table-cell-row-hover {
    background: ${(props) => (props.isAdmin ? 'transparent' : '#eeeff0')};
    cursor: ${(props) => (props.isAdmin ? 'default' : 'pointer')};
  }

  .ant-table-cell-row-hover .on-row-hover {
    display: flex;
  }

  .ant-table-pagination.ant-pagination {
    padding-right: 30px;
  }
`;

interface ByValue {
  label: string;
}

export interface DashboardInitialState {
  byDeliveryDate: ByValue[];
  byRegion: RegionalData[];
  byRackingType: PipelineDataObject[];
  byRank: Metrics[];
  byProjectSize: Metrics[];
}

export const initialState: DashboardInitialState = {
  byDeliveryDate: [],
  byRegion: [],
  byRackingType: [],
  byRank: [],
  byProjectSize: [],
};

type ReducerAction = {
  payload: any;
  type: keyof typeof initialState;
};

export const chartsReducer = (
  state: DashboardInitialState,
  action: ReducerAction
) => {
  switch (action.type) {
    case DATA_SET.DELIVERY_DATE:
      return {
        ...state,
        byDeliveryDate: action.payload,
      };
    case DATA_SET.REGIONS:
      return {
        ...state,
        byRegion: action.payload,
      };
    case DATA_SET.RACKING_TYPES:
      return {
        ...state,
        byRackingType: action.payload,
      };
    case DATA_SET.RANK:
      return {
        ...state,
        byRank: action.payload,
      };
    case DATA_SET.PROJECT_SIZE:
      return {
        ...state,
        byProjectSize: action.payload,
      };
    default:
      throw new Error();
  }
};

const allModulesSelectionValue = 'All Modules';
const defaultModuleOptions = [
  {
    value: allModulesSelectionValue,
    text: allModulesSelectionValue,
  },
];

const dataSets: Array<DATA_SET> = [
  DATA_SET.DELIVERY_DATE,
  DATA_SET.RANK,
  DATA_SET.REGIONS,
  DATA_SET.RACKING_TYPES,
  DATA_SET.PROJECT_SIZE,
];

const Dashboard: React.FC = () => {
  const [pipelineMetricsState, dispatch] = React.useReducer(
    chartsReducer,
    initialState
  );
  const [moduleMetricsState, dispatchModuleMetrics] = React.useReducer(
    chartsReducer,
    initialState
  );
  const [arePipelineMetricsLoading, setArePipelineMetricsLoading] =
    React.useState(true);
  const [areModuleMetricsLoading, setAreModuleMetricsLoading] =
    React.useState(true);
  const [isTableLoading, setIsTableLoading] = React.useState(true);
  const [tableData, setTableData] = React.useState<ActiveProjectsData[]>([]);
  const [selectedModule, setSelectedModule] = React.useState(
    allModulesSelectionValue
  );
  const [moduleOptions, setModuleOptions] =
    React.useState(defaultModuleOptions);
  const [allTimePipelineSize, setAllTimePipelineSize] = React.useState('');
  const [activePipelineSize, setActivePipelineSize] = React.useState('');
  const [activeProjectSizeForModule, setActiveProjectSizeForModule] =
    React.useState<number>();
  const [activeProjectCountForModule, setActiveProjectCountForModule] =
    React.useState<number | string>('');
  const [activeNumberOfProjects, setActiveNumberOfProjects] =
    React.useState('');
  const {
    handleTableChange,
    sortKey,
    sortValue,
    filterMap,
    pagination,
    resetPagination,
  } = usePersistTableValues();
  const { user, isLoading: isAuthLoading } = useAuth();
  const [getAllCompanies] = useLazyGetAllCompaniesQuery();

  const tableActions = {
    render: (_: unknown, record: ProjectResumeColumn) => (
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: 1 }}>
          {R.pathOr('-', ['topModule', 'rank'], record)}
        </div>
        {!isUserAdmin(user) && (
          <div
            className="on-row-hover"
            style={{ color: theme.colors.graphite }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ArrowCircleRight />
            </div>
          </div>
        )}
      </div>
    ),
  };

  const history = useHistory();
  const { company } = user;

  const columns = getColumns(
    sortKey,
    getSortOrderValue(sortValue),
    filterMap,
    tableActions,
    isUserAdmin(user)
  );

  const [selectedVendorId, setSelectedVendorId] = React.useState(
    isUserAdmin(user) ? 'all' : company?.uuid || ''
  );
  const isSelectedVendorAll = selectedVendorId === 'all';

  const onTableRowClick = (record: any) => {
    return {
      onClick: () => {
        history.push(
          ROUTER_PATHS.vendors.PROJECT_PRICING(
            company!.uuid,
            record.project_details_uuid
          )
        );
      },
    };
  };

  const handleGetVendorPipeline = (
    vendorPipelineData: PipelineData | undefined
  ) => {
    if (!vendorPipelineData) {
      return;
    }
    const { byDeliveryDate, byRegion, byRackingType, byRank, byProjectSize } =
      processMetricData(dataSets, vendorPipelineData);

    // cast size values as "any" since the API returns one as a string and one as a number
    setAllTimePipelineSize(
      convertMwToGw(vendorPipelineData.allTimeSizeMw)?.toString() ?? ''
    );
    setActivePipelineSize(
      convertMwToGw(vendorPipelineData.totalMw)?.toString() ?? ''
    );
    setActiveNumberOfProjects(vendorPipelineData.count.toString());

    dispatch({ type: DATA_SET.DELIVERY_DATE, payload: byDeliveryDate });
    dispatch({ type: DATA_SET.REGIONS, payload: byRegion });
    dispatch({ type: DATA_SET.RACKING_TYPES, payload: byRackingType });
    dispatch({ type: DATA_SET.RANK, payload: byRank });
    dispatch({ type: DATA_SET.PROJECT_SIZE, payload: byProjectSize });
  };

  const getMetricsForModule = (
    moduleId: string,
    moduleMetricsData: PipelineDataValues
  ) => {
    if (!moduleMetricsData) {
      return null;
    }

    // memoModuleMetrics(moduleId, moduleMetricsData);
    setActiveProjectSizeForModule(moduleMetricsData.totalMw);
    setActiveProjectCountForModule(moduleMetricsData.count);
    const { byDeliveryDate, byRegion, byRackingType, byRank, byProjectSize } =
      processMetricData(dataSets, moduleMetricsData as unknown as PipelineData);

    dispatchModuleMetrics({
      type: DATA_SET.DELIVERY_DATE,
      payload: byDeliveryDate,
    });
    dispatchModuleMetrics({ type: DATA_SET.REGIONS, payload: byRegion });
    dispatchModuleMetrics({
      type: DATA_SET.RACKING_TYPES,
      payload: byRackingType,
    });
    dispatchModuleMetrics({ type: DATA_SET.RANK, payload: byRank });
    dispatchModuleMetrics({
      type: DATA_SET.PROJECT_SIZE,
      payload: byProjectSize,
    });
    return null;
  };

  const handleModuleChange = async (moduleId: string) => {
    setSelectedModule(moduleId);
  };

  const getSelectedModule = () => {
    return R.equals(selectedModule, allModulesSelectionValue)
      ? ''
      : selectedModule;
  };

  const isVendorIdSelected = () =>
    selectedVendorId && selectedVendorId !== 'all';

  const {
    data: vendorModulesList,
    isFetching: vendorModulesListIsFetching,
    isLoading: vendorModulesListIsLoading,
  } = selectedVendorId && isUserAdmin(user)
    ? useGetAllModulesQuery({ vendorId: selectedVendorId })
    : useGetModulesQuery(undefined, {
        pollingInterval: getMilliSecondsInDays(1),
      });

  useEffect(() => {
    if (vendorModulesList) {
      setModuleOptions(
        vendorModulesList.length
          ? [
              ...defaultModuleOptions,
              ...R.map(
                (module: { name: string; uuid: string }) => ({
                  text: module.name,
                  value: module.uuid,
                }),
                vendorModulesList
              ),
            ]
          : []
      );
    }
  }, [vendorModulesList, selectedVendorId]);

  const {
    data: vendorPipelineData,
    isFetching: vendorPipelineDataIsFetching,
    isLoading: vendorPipelineDataIsLoading,
  } = useGetVendorPipelineQuery(undefined, {
    pollingInterval: getMilliSecondsInHours(2),
  });

  React.useEffect(() => {
    handleGetVendorPipeline(vendorPipelineData as unknown as PipelineData);
    setArePipelineMetricsLoading(
      vendorPipelineDataIsFetching || vendorPipelineDataIsLoading
    );
  }, [vendorPipelineData]);

  const {
    data: vendorModuleMetricsData,
    isFetching: vendorModuleMetricsDataIsFetching,
    isLoading: vendorModuleMetricsDataIsLoading,
  } = isSelectedVendorAll && isUserAdmin(user)
    ? useGetAllModuleMetricsQuery(undefined)
    : useGetModuleMetricsQuery({
        vendorId:
          isVendorIdSelected() && isUserAdmin(user)
            ? selectedVendorId
            : company!.uuid,
        moduleId: getSelectedModule(),
      });

  React.useEffect(() => {
    getMetricsForModule(
      getSelectedModule(),
      vendorModuleMetricsData as unknown as PipelineDataValues
    );
    setAreModuleMetricsLoading(
      vendorModuleMetricsDataIsFetching || vendorModuleMetricsDataIsLoading
    );
  }, [selectedModule, vendorModuleMetricsData]);

  const {
    data: vendorActiveProjectsData,
    isFetching: vendorActiveProjectsDataIsFetching,
    isLoading: vendorActiveProjectsDataIsLoading,
  } = isSelectedVendorAll && isUserAdmin(user)
    ? useGetAllActiveProjectsQuery(undefined)
    : useGetActiveProjectsQuery(
        {
          vendorId:
            isVendorIdSelected() && isUserAdmin(user)
              ? selectedVendorId
              : company!.uuid,
        },
        {
          pollingInterval: getMilliSecondsInHours(2),
        }
      );

  React.useEffect(() => {
    setTableData(vendorActiveProjectsData || []);
    setIsTableLoading(
      vendorActiveProjectsDataIsFetching || vendorActiveProjectsDataIsLoading
    );
  }, [vendorActiveProjectsData]);

  React.useEffect(() => {
    setTableData((projects) => {
      const sortedModules = R.sortWith(
        isSelectedVendorAll
          ? [R.descend((project) => dayjs(project.createdAt).unix())]
          : [R.ascend((project) => project.topModule?.rank || 0)],
        projects || []
      );
      return sortedModules;
    });
    setIsTableLoading(
      vendorActiveProjectsDataIsFetching || vendorActiveProjectsDataIsLoading
    );
  }, [vendorActiveProjectsData, selectedVendorId]);

  const emptyDeliveryDateDataSet = getEmptyDataSet(DATA_SET.DELIVERY_DATE);

  const [vendorCompanies, setVendorCompanies] = React.useState<CompanyType[]>(
    []
  );

  const [isVendorCompaniesLoading, setIsVendorCompaniesLoading] =
    React.useState(false);

  const handleGetVendorCompanies = async () => {
    setIsVendorCompaniesLoading(true);
    try {
      const response = await getAllCompanies({
        limitToActiveModules: true,
      }).unwrap();
      const filteredVendorCompanies = response.filter(
        (comp) => comp.company_type === COMPANY_TYPES.vendor.code
      );
      setVendorCompanies(filteredVendorCompanies);
    } catch (error) {
      catchError({ error });
    } finally {
      setIsVendorCompaniesLoading(false);
    }
  };

  React.useEffect(() => {
    if (isUserAdmin(user)) {
      handleGetVendorCompanies();
    }
  }, []);

  const handleSelectVendor = (value: string) => {
    setSelectedVendorId(value);
    setSelectedModule(allModulesSelectionValue);
    resetPagination();
  };

  return (
    <Page title="Dashboard">
      <div style={containerLayoutStyles}>
        <Pipeline
          loading={arePipelineMetricsLoading}
          allTimePipelineSize={allTimePipelineSize}
          activePipelineSize={activePipelineSize}
          activeNumberOfProjects={activeNumberOfProjects}
          pipelineMetricsState={pipelineMetricsState}
          emptyDeliveryDateDataSet={emptyDeliveryDateDataSet}
        />
        <MetricsContainer
          style={{
            gridColumn: '1 / 3',
            justifyContent: 'center',
          }}
        >
          <ModuleMetrics
            selectedVendorId={selectedVendorId}
            title={
              isAuthLoading || isVendorCompaniesLoading ? '' : company!.name
            }
            vendors={vendorCompanies}
            onSelectVendor={handleSelectVendor}
            moduleId={selectedModule}
            moduleOptions={moduleOptions}
            activePipelineMw={activeProjectSizeForModule}
            activeProjectCount={activeProjectCountForModule}
            byDeliveryDate={moduleMetricsState.byDeliveryDate}
            byRank={moduleMetricsState.byRank}
            byProjectSize={moduleMetricsState.byProjectSize}
            byRegion={moduleMetricsState.byRegion}
            onModuleChange={handleModuleChange}
            isLoading={
              areModuleMetricsLoading ||
              vendorModulesListIsFetching ||
              vendorModulesListIsLoading ||
              vendorActiveProjectsDataIsFetching ||
              vendorActiveProjectsDataIsLoading
            }
          />
          <div
            style={{
              width: '100%',
              textAlign: 'left',
              padding: '32px 30px 28px',
            }}
          >
            <Typography.VendorSectionHeader style={{ marginBottom: '12px' }}>
              Active Projects
            </Typography.VendorSectionHeader>
            <Typography.VendorSectionSubtitle>
              Select a project to view pricing analysis.
            </Typography.VendorSectionSubtitle>
          </div>
          <div style={{ padding: '0', width: '100%' }}>
            <ProjectsTable
              isAdmin={isUserAdmin(user) as boolean}
              rowKey="uuid"
              columns={columns as any}
              dataSource={tableData}
              locale={{
                emptyText: isTableLoading ? null : (
                  <NoDataAvailable
                    styles={{
                      height: 340,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    content="No projects available. Please check back later."
                  />
                ),
              }}
              style={{ width: '100%' }}
              className=""
              pagination={pagination}
              onChange={handleTableChange as any}
              onRow={!isUserAdmin(user) ? onTableRowClick : undefined}
              loading={isTableLoading}
            />
          </div>
        </MetricsContainer>
      </div>
    </Page>
  );
};

export default Dashboard;
